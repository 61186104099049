var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"10","sm":"12"}},[_c('h3',{staticClass:"mb-4"},[_vm._v(" Welcome, please select a package ")]),_c('p')]),_vm._l((_vm.packages),function(pkg){return [_c('v-col',{key:pkg._id,attrs:{"cols":"12","lg":"6","md":"8","sm":"10"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(pkg.name))]),_c('v-card-subtitle',[_vm._v(_vm._s(pkg.description))]),_c('v-card-text',[_c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-text',[_c('strong',[_vm._v("Package includes:")]),_c('ul',[_vm._l((pkg.club_products),function(club_product){return _c('li',{key:club_product._id},[_vm._v(" "+_vm._s(club_product.name)+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.direct_product.requires_health_statement)?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-medical-bag ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Requires Health Statement")])])],1)}),_vm._l((pkg.direct_products),function(direct_product){return _c('li',{key:direct_product._id},[_vm._v(" "+_vm._s(direct_product.name)+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(direct_product.requires_health_statement)?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-medical-bag ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Requires Health Statement")])])],1)})],2)])],1),_c('p',{staticClass:"mt-5 h6"},[_vm._v(" Price: $"+_vm._s(_vm.formatCurrency(_vm.packagePrice(pkg._id)))+" ")])],1),_c('v-card-actions',{staticClass:"button-set"},[_c('v-spacer'),(
              pkg.direct_products.some(
                function (element) { return element.requires_health_statement === true; }
              ) ||
                pkg.club_products.some(
                  function (element) { return element.requires_health_statement === true; }
                )
            )?[_c('health-statement')]:_vm._e(),(
              pkg.direct_products.some(
                function (element) { return element.product_entry.length; }
              ) ||
                pkg.club_products.some(
                  function (element) { return element.product_entry.length; }
                )
            )?[_c('prize-destination-form'),_c('upload-medical'),_c('product-entry-form',{attrs:{"package-id":pkg}})]:_vm._e(),_c('v-btn',{attrs:{"disabled":pkg.direct_products.some(
                function (element) { return element.requires_health_statement === true; }
              ) && _vm.newHealthStatementCreated !== true},on:{"click":function($event){$event.stopPropagation();return _vm.createOrder(pkg._id)}}},[_vm._v(" Purchase ")])],2)],1)],1)]}),_c('v-snackbar',{attrs:{"timeout":"5000"},model:{value:(_vm.showPaymentFailureMessage),callback:function ($$v) {_vm.showPaymentFailureMessage=$$v},expression:"showPaymentFailureMessage"}},[[_c('h4',[_vm._v("Order not placed")]),_vm._v(" The order was not placed, please ensure you have entered credit card details correctly and try again later. "),_c('div',[_vm._v("Order has not been placed.")])]],2),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.paymentDialog),callback:function ($$v) {_vm.paymentDialog=$$v},expression:"paymentDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Enter Payment Details")]),_c('v-container',{ref:"paymentCard"}),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"darken-1","text":""},on:{"click":function($event){return _vm.onStripeElementCancel()}}},[_vm._v(" close ")]),(_vm.paymentSubmittable)?_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":_vm.checkout}},[_vm._v(" Submit ")]):_vm._e()],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }