<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn
        class="rounded ma-2"
        dark
        tile
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-plus</v-icon>
        Health Statement
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Health Statement</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="healthStatmentForm"
          v-model="valid"
        >
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="newHealthStatement.doctor_full_name"
                  label="Doctor Full Name"
                  :rules="requiredRules"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="newHealthStatement.doctor_practice"
                  label="Medical Practice"
                  :rules="requiredRules"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="newHealthStatement.doctor_phone"
                  label="Phone"
                  :rules="requiredRules"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="newHealthStatement.doctor_address_line_1"
                  label="Address Line 1"
                  :rules="requiredRules"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="newHealthStatement.doctor_address_line_2"
                  label="Address Line 2"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="newHealthStatement.doctor_suburb"
                  label="Suburb"
                  :rules="requiredRules"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="newHealthStatement.doctor_postcode"
                  label="Postcode"
                  :rules="requiredRules"
                  required
                  type="number"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="newHealthStatement.doctor_state"
                  label="State"
                  :rules="requiredRules"
                  :items="states"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-radio-group
                  v-model="newHealthStatement.medication"
                  label="Are you currently or have you taken any medication in the last 12 months?"
                  row
                >
                  <v-radio
                    label="Yes"
                    :value="true"
                  />
                  <v-radio
                    label="No"
                    :value="false"
                  />
                </v-radio-group>
                <template v-if="newHealthStatement.medication">
                  <v-textarea
                    v-model="newHealthStatement.medication_input"
                  />
                </template>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-radio-group
                  v-model="newHealthStatement.allergies"
                  label="Do you have any allergies?"
                  row
                >
                  <v-radio
                    label="Yes"
                    :value="true"
                  />
                  <v-radio
                    label="No"
                    :value="false"
                  />
                </v-radio-group>
                <template v-if="newHealthStatement.allergies">
                  <v-textarea
                    v-model="newHealthStatement.allergies_input"
                  />
                </template>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-radio-group
                  v-model="newHealthStatement.recentOperations"
                  label="Have you ever had any surgical operations?"
                  row
                >
                  <v-radio
                    label="Yes"
                    :value="true"
                  />
                  <v-radio
                    label="No"
                    :value="false"
                  />
                </v-radio-group>
                <template v-if="newHealthStatement.recentOperations">
                  <v-textarea
                    v-model="newHealthStatement.recentOperations_input"
                  />
                </template>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-radio-group
                  v-model="newHealthStatement.specialisedIllness"
                  label="Have you ever suffered from an illnes that has required treatnebt by a Specialist or Hospital Doctor?"
                  row
                >
                  <v-radio
                    label="Yes"
                    :value="true"
                  />
                  <v-radio
                    label="No"
                    :value="false"
                  />
                </v-radio-group>
                <template v-if="newHealthStatement.specialisedIllness">
                  <v-textarea
                    v-model="newHealthStatement.specialisedIllness_input"
                  />
                </template>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-radio-group
                  v-model="newHealthStatement.conditions"
                  label="Do you suffer from any other condition that may affect or inhibit your ability to drive?"
                  row
                >
                  <v-radio
                    label="Yes"
                    :value="true"
                  />
                  <v-radio
                    label="No"
                    :value="false"
                  />
                </v-radio-group>
                <template v-if="newHealthStatement.conditions">
                  <v-textarea
                    v-model="newHealthStatement.conditions_input"
                  />
                </template>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-radio-group
                  v-model="newHealthStatement.eyeProblems"
                  label="Do you wear glasses or contact lenses?"
                  row
                >
                  <v-radio
                    label="Yes"
                    :value="true"
                  />
                  <v-radio
                    label="No"
                    :value="false"
                  />
                </v-radio-group>
                <template v-if="newHealthStatement.eyeProblems">
                  <v-textarea
                    v-model="newHealthStatement.eyeProblems_input"
                  />
                </template>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-radio-group
                  v-model="newHealthStatement.smoker"
                  label="Are you currently a smoker?"
                  row
                >
                  <v-radio
                    label="Yes"
                    :value="true"
                  />
                  <v-radio
                    label="No"
                    :value="false"
                  />
                </v-radio-group>
                <template v-if="newHealthStatement.smoker">
                  <v-textarea
                    v-model="newHealthStatement.smoker_input"
                  />
                </template>
              </v-col>
            </v-row>
            <v-divider />
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="newHealthStatement.declaration_accepted"
                  required
                  :rules="requiredRules"
                  label="I have answered all of the questions above honestly and completely, to the best of my knowledge and is not misleading or deceptive. I understand that a medical opinion regarding my fitness to compete will be formulated based upon the answers I have provided here and I consent to Speedway Australia using this information to form an opinion. I will advise Speedway Australia if I suffer any condition, illness or injury which may affect my ability to compete in speedway racing. I will abide by the Speedway Australia Drug & Alcohol policy at all times. Should there be any change to the information I have supplied, I will advise Speedway Australia at the earliest possible convenience. I agree to provide Speedway Australia with any and all health or medical information requested. I acknowledge that Speedway Australia may need to request additional health and medical information from my nominated medical practitioner. I unconditionally authorise my medical practitioner or qualified party to provide medical information about me upon request by Speedway Australia. I acknowledge that Speedway Australia, at their discretion, may disclose the information provided on this form to third parties in the event Speedway Australia deems it important to my health and safety."
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          @click="saveHealthStatement"
          :disabled="!valid"
        >
          Save
        </v-btn>
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
        >
          {{ text }}

          <template v-slot:action="{attrs}">
            <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'NewSMC',
    components: {
      VuePerfectScrollbar,
    },
    data: () => ({
      medication: null,
      allergies: null,
      recentOperations: null,
      specialisedIllness: null,
      conditions: null,
      eyeProblems: null,
      smoker: null,
      menu2: {},
      todoSearch: '',
      todos: [],
      selected: [''],
      /// ///-----/////
      snackbar: false,
      isOpen: false,
      isBlock: false,
      checkbox: false,
      dialog: false,
      isLineThrough: false,
      text: 'Task Name Must Be Required...',
      timeout: 2000,
      // item: 1,
      // itemsTwo: 0,
      drawer: null,
      newEditTodo: '',
      newUser: {
        city: 'Default',
        country: 'Australia',
      },
      states: [
        'VIC',
        'NSW',
        'ACT',
        'QLD',
        'NT',
        'WA',
        'SA',
        'TAS',
      ],
      valid: false,
      requiredRules: [
        v => !!v || 'This field is required',
      ],
    }),
    created () {
      this.newHealthStatement.registrant = this.loggedInUser._id
    },
    methods: {
      ...mapActions(['healthStatementCreated']),
      saveUser () {
        this.dialog = false
        this.$store.dispatch('createUser', { newUserDetails: this.newUser, role: 'ROLE_SMC' })
      },
      saveHealthStatement () {
        this.dialog = false
        /**
         * TODO make this only return true once the form has been validated;
         */
        this.$refs.healthStatmentForm.validate()
        if (this.valid) {
          this.healthStatementCreated(true)
        }
      },
    },
    computed: {
      ...mapGetters(['newHealthStatement', 'newHealthStatementCreated', 'loggedInUser']),
      filterSearchList () {
        return this.todos.filter((post) => {
          return post.title
                    ?.toLowerCase()
                    .includes(this.todoSearch?.toLowerCase())
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.apps-wrapper {
    .nested-sidebar-toggle {
        @media (min-width: 959px) {
            display: none;
        }
    }
}
.app-content-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    height: calc(100vh - 200px);

    .app-overlay {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        display: none;
        z-index: 2;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            &.open {
                display: block;
                transition: all 0.3s ease-in;
            }
        }
    }
    .app-sidebar {
        width: 280px;
        position: relative;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            position: absolute;
            left: -340px;
            z-index: 5;
            height: calc(100vh - 120px);
            transition: all 0.3s ease-in;
            &.open {
                left: 0;
            }
        }
        .app-sidebar-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-sidebar-body {
            // overflow-y: scroll;
            // height: 100%;
            padding-top: 3.375rem;
            width: 280px;
        }
    }

    .app-content {
        width: calc(100% - 280px);
        @media only screen and (max-width: 959px) {
            width: 100%;
        }
        // margin-left: 30px;
        .app-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-body {
            flex: 1 1 auto;
            padding-top: 3.375rem;
            // overflow-y: scroll;
            height: calc(100% - 130px);
        }
        .app-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 130px;
        }
    }
}
.app-icon {
    display: none;
    @media only screen and (max-width: 959px) {
        display: block;
    }
}
.eg-filemanager {
    background-color: #fff;
}
.filemanager-content {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
    &.--filemanager {
        .nested-sidebar-toggle {
            @media (min-width: 959px) {
                display: none;
            }
        }
    }
}
</style>
