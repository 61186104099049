<template>
  <v-row justify="center">
    <v-col
      cols="12"
      md="10"
      sm="12"
      class="text-center"
    >
      <h3 class="mb-4">
        Welcome, please select a package
      </h3>
      <p />
    </v-col>
    <template v-for="pkg in packages">
      <v-col
        :key="pkg._id"
        cols="12"
        lg="6"
        md="8"
        sm="10"
      >
        <v-card>
          <v-card-title>{{ pkg.name }}</v-card-title>
          <v-card-subtitle>{{ pkg.description }}</v-card-subtitle>
          <v-card-text>
            <v-card elevation="1">
              <v-card-text>
                <strong>Package includes:</strong>
                <ul>
                  <li
                    v-for="club_product in pkg.club_products"
                    :key="club_product._id"
                  >
                    {{ club_product.name }}
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="direct_product.requires_health_statement"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-medical-bag
                        </v-icon>
                      </template>
                      <span>Requires Health Statement</span>
                    </v-tooltip>
                  </li>
                  <li
                    v-for="direct_product in pkg.direct_products"
                    :key="direct_product._id"
                  >
                    {{ direct_product.name }}
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="direct_product.requires_health_statement"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-medical-bag
                        </v-icon>
                      </template>
                      <span>Requires Health Statement</span>
                    </v-tooltip>
                  </li>
                </ul>
              </v-card-text>
            </v-card>
            <p class="mt-5 h6">
              Price: ${{ formatCurrency(packagePrice(pkg._id)) }}
            </p>
          </v-card-text>
          <v-card-actions class="button-set">
            <v-spacer />
            <template
              v-if="
                pkg.direct_products.some(
                  element => element.requires_health_statement === true
                ) ||
                  pkg.club_products.some(
                    element => element.requires_health_statement === true
                  )
              "
            >
              <health-statement />
            </template>
            <template
              v-if="
                pkg.direct_products.some(
                  element => element.product_entry.length
                ) ||
                  pkg.club_products.some(
                    element => element.product_entry.length
                  )
              "
            >
              <prize-destination-form />
              <upload-medical />

              <product-entry-form :package-id="pkg" />
            </template>

            <v-btn
              :disabled="
                pkg.direct_products.some(
                  element => element.requires_health_statement === true
                ) && newHealthStatementCreated !== true
              "
              @click.stop="createOrder(pkg._id)"
            >
              Purchase
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
    <v-snackbar
      v-model="showPaymentFailureMessage"
      timeout="5000"
    >
      <template>
        <h4>Order not placed</h4>
        The order was not placed, please ensure you have entered credit card details correctly
        and try again later.
        <div>Order has not been placed.</div>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="paymentDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>Enter Payment Details</v-card-title>
        <v-container ref="paymentCard" />
        <v-card-actions>
          <v-btn
            color="darken-1"
            text
            @click="onStripeElementCancel()"
          >
            close
          </v-btn>
          <v-btn
            v-if="paymentSubmittable"
            color="primary darken-1"
            text
            @click="checkout"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import HealthStatement from './HealthStatementDiag.vue'
  import ProductEntryForm from './ProductEntryForm.vue'
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import { utilities } from '../../../mixins/utility'
  import PrizeDestinationForm from './prizeDestinationForm.vue'
  import UploadMedical from './UploadMedical'
  // let stripe = Stripe('pk_test_51HPn4PFU21qe166Hrgn5AzWRUKNfXMe6qmtbIGZVGoBgReQPSI6MLQjv8VjBMyvn8YSzStXy2mc54dyiD50P04Bb00DtAa8phj');
  // manoj test key
  // const stripe = Stripe('pk_test_CVjuHsBww6uDIz5RXC7tF7tD005EcRiyjM')
  const stripe = Stripe(
    'pk_live_51J4yHULmS5CcBkMzb8Epahll1kzy09X3T3chjV9Bd9ODJS1jteMXnGr98CC48e05Wvp4BYTF0VjyoX8bxG7yn9uC00FYfz48Nv',
  )

  const elements = stripe.elements()
  let card

  export default {
    name: 'Packages',
    components: {
      HealthStatement,
      ProductEntryForm,
      PrizeDestinationForm,
      UploadMedical,
    },
    mixins: [utilities],
    data () {
      return {
        selectedProducts: [],
        paymentDialog: false,
        paymentSubmittable: true,
        orderId: undefined,
        showPaymentFailureMessage: false,
      }
    },
    created () {
      this.getRegistrantPackages()
    },
    computed: {
      ...mapGetters([
        'packages',
        'packagePrice',
        'newHealthStatement',
        'newHealthStatementCreated',
        'clientSecret',
        'orderEntry',
        'getOrderInProgressId',
      ]),
    },
    methods: {
      ...mapActions([
        'getRegistrantPackages',
        'createHealthStatement',
        'createRegistrantOrder',
        'cancelRegistrantOrder',
        'sendOrderFinalizeFlag',
      ]),
      ...mapMutations([
        'SET_PRIZEDESTINATIONDIALOG_STATE',
        'SET_ORDER_IN_PROGRESS_ID_',
        'CLEAR_ORDER_IN_PROGRESS_ID_',
      ]),
      onStripeElementCancel () {
        // Handles cancelings stripe card detail entry
        if (this.getOrderInProgressId) {
          this.cancelRegistrantOrder(this.getOrderInProgressId).then(() => {
            this.paymentDialog = false
          })
        } else {
          this.paymentDialog = false
        }
      },
      createOrder (packageId) {
        this.paymentDialog = true
        const options = {
          style: {
            base: {
              color: this.$vuetify.theme.dark ? '#fff' : '#000',
            },
          },
          hidePostalCode: true,
        }
        this.$nextTick(function () {
          card = elements.create('card', options)
          card.mount(this.$refs.paymentCard)
        })
        const order = {
          packageId: packageId,
          paymentMethod: 'card',
          healthStatement: this.newHealthStatement,
          orderEntries: this.orderEntry,
        }
        this.createRegistrantOrder(order).then(({ orderId }) => {
          this.orderId = orderId
        })
      },
      checkout () {
        this.paymentSubmittable = false
        stripe.createToken(card).then((result) => {
          if (result.error) {
            this.onStripeElementCancel()
            const targetElement = elements.getElement('card')
            targetElement.clear()
            this.paymentSubmittable = true
            this.showPaymentFailureMessage = true
            throw new Error('No payment details entered!')
          }

          stripe
            .confirmCardPayment(this.clientSecret, {
              payment_method: {
                card: card,
              },
            })
            .then(res => {
              if (res.error) {
                console.log('ERROR with stripe res confirm payment')
                throw new Error(`Error - ${JSON.stringify(res.error)}`)
              }
              this.CLEAR_ORDER_IN_PROGRESS_ID_()
              // const targetElement = elements.getElement('card')
              // targetElement.destroy()
              // elements = stripe.elements()
              this.sendOrderFinalizeFlag(this.orderId)
              if (res.paymentIntent.status === 'requires_capture') {
                this.SET_PRIZEDESTINATIONDIALOG_STATE(true)
                this.paymentSubmittable = true
              // this.$router.push('/registrant/orders')
              }
            })
        })
      },
    },
  }
</script>

<style>
.button-set {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

@media only screen and (max-width: 600px) {
  .button-set {
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
}
</style>
